<template>
  <div>
	<div class="videocontainer">
		<video class="videostyle" loop autoplay muted playsinline src="../../assets/video/solution_video.mp4" type="video/mp4">
		</video>
	</div> 
	<!-- <div class="banner img_up" v-show="isshow">
		<div  style="width: 100%; height: 100%;text-align: center;">
		  <div class="content">
			<div class="title">解决方案</div>
				<h2
				  class="banner-text text-top animated"
				  data-animation="flipInX"
				  animation-status="true"
				  style="
					visibility: visible;
					animation-name: flipInX;
					animation-delay: 0s;
				  "
				>
				  忆能科技
				</h2>
				<p
				  class="banner-text2 animated"
				  data-animation="fadeInUp"
				  data-delay="2"
				  animation-status="true"
				  style="
					visibility: visible;
					animation-name: fadeInUp;
					animation-delay: 0.4s;
				  "
				>一站式分布式储能解决方案提供商，让"储能更安全，更高效"
				</p>
			<div class="banner-img"></div>
		  </div>
		</div>
	</div> -->
  </div>
</template>

<script>
	export default {
	  props: {
	    
	  },
	  data() { 
	  	return {
	      isshow: true,
	  	}
	  },
	  created() {
	    
	  },
	  computed: {
	    
	  },
	  
	  beforeDestroy() {
	    
	  },
	  methods: {
	  }
	   
	};
</script>

<style lang="scss" scoped>
	.videocontainer {
		// width: calc(100vw -18px);
		// height: 40vw;
		// opacity: 0;
	}
	.videostyle {
		width: 100%;
		height: 100%;
		// opacity: 0;
		// 预留导航头部空间80px
		margin-top: 80px;
	}
	.img_up {
		position: absolute;
		left: 0;
		top: 0;
	}
	.img_down {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -100;
	}
</style>

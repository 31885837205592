<template>
	<div class="banner" :class="backgroundUrl==='' ? datas[0]:backgroundUrl">
	  <!-- <Carousel
		type="card"
		  :autoplay="true"
		  :duration="2000"
		  :initIndex="0"
		  :direction="true"
		  directionMode="hover"
		  :directionSize="20"
		  directionColor="skyblue"
		  :indicator="true"
		  indicatorMode="always"
		  indicatorColor="white"
		  indicatorActiveColor="skyblue"
		  @before-moving="beforeMoving"
		  @after-moving="afterMoving"
		  >
		  <CarouselItem v-for="(item, index) in datas" :key="index" :idx="index">
			  <img  :src="item" />
		  </CarouselItem>
	  </Carousel> -->
	  
	  <!-- <div style="width: 100%; height: 100%; background: rgba(0, 0, 0, 0.2)">
	    
	  </div> -->
		
    </div>
</template>

<script>
	export default {
	  props:{
		  datas:{
			  type:Array,
			  required:true,
		  },	
	  },
	  data() {
	      return {
			backgroundUrl: "",
			index:0,
			}
		},
	  mounted () {
		  this.comparison();
		this.currentUrl();
	  },
	  methods: {
		  currentUrl () {
		  //每三秒更换一次背景图地址
		  setInterval(this.comparison, 3000);
		},
		comparison () {
		//index、backgroundUrl 都是在return里设置的默认值index默认0，backgroundUrl 默认''
		  if (this.datas.length > 0) {
			let i = this.datas.length
			if (this.index >= i) {
			  this.index = 0
			}
			this.backgroundUrl = 'pic' + this.datas[this.index]
			// console.log("xxxxx",this.backgroundUrl ,)
			this.index = this.index + 1
		  }
		}
	  }

	}		
</script>
<style scoped>
.banner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* height: 480px; */
  /* 留出头部导航空间80px */
  margin-top: 80px;
  padding-top: 80px;
  background-color: #000;
  /* background: url(../../assets/images/banner/2.jpg) 20%; */
  /* background-size: 100% 100%; */
}
@media screen and (max-width:750px) {
	.banner{
		margin-top: 56px;
	}
}
.picproduct0 {
    background: url(../../assets/images/banner/product.jpg) no-repeat center;
	background-size: cover;
	height: 25vw;
	width: calc(100vw -16px);
 }
 
 .picaboutus {
     background: url(../../assets/images/banner/aboutus.jpg) no-repeat center;
 	background-size: cover;
 	height: 25vw;
 	width: calc(100vw -16px);
  }
.piccontaceus {
  background: url(../../assets/images/banner/contaceus.jpg) no-repeat center;
background-size: cover;
height: 25vw;
width: calc(100vw -16px);
}
.picnews {
   background: url(../../assets/images/banner/news.jpg) no-repeat center;
background-size: cover;
height: 25vw;
width: calc(100vw -16px);
}
.picproject {
	background: url(../../assets/images/banner/project.jpg) no-repeat center;
	background-size: cover;
	height: 25vw;
	width: calc(100vw -16px);
 }
 .picsolution {
 	background: url(../../assets/images/banner/solution.jpg) no-repeat center;
 	background-size: cover;
 	height: 25vw;
 	width: calc(100vw -16px);
  }
 

</style>

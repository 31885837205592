<template>
  <div>
	<commonTitle v-bind:datas="datas"></commonTitle>
	<!-- 公司概况 -->
    <div class="advantage clearfix pab60" style="background-color: #FFFFFF;"><!-- 白色背景覆盖advantage背景色-->
      <header class="mgt60">
        <p class="font_24 center font_b adv-title ">公司概况</p>
		<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
      </header>
      <div class="container mgt40 adv-bgc" style="font-family: 'Arial Normal', 'Arial';
			font-weight: 400;font-size: 16px;color: #555555; background-color: #FFFFFF; line-height: 28px">
			<p>
			  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;忆能科技（全称：成都忆能科技有限公司）作为一家新能源科技企业，致力于为客户提供专业化的新能源储能产品及服务，是全球领先的新能源储能技术集成开发、完整自主知识产权的服务商。
			公司成立于2018年，坐落于成都高新技术开发区，是通过国家认定的高新技术企业。忆能科技通过自主研发和联合开发的方式为客户构建一整套完整、安全、可扩展性的储能解决方案，并在美国、加拿大、智利、墨西哥等地大规模应用。 
			</p>
			<p>
			  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司的核心成员主要来自比亚迪、宁德时代等国内知名新能源企业，并拥有多年储能行业经验。公司的核心业务：新能源储能平台服务，EMS、SCADA、 BMS等智能硬件，储能项目开发，现场施工及运营管理
			。我们的愿景是成为全球领先的储能平台服务商，共创绿色家园。
			</p>
			<!-- <p class="min_font">
			  &nbsp;&nbsp;&nbsp;&nbsp;
			</p> -->
      </div>
	  <div style="width: 70%; margin: auto;">
		  <autoPlay ></autoPlay>
	  </div>
    </div>
	<!-- 数据展示 -->
	<div class="advantage clearfix" style="width: 100%;max-height: 180px;">
	  <div  style="font-family: 'Arial Normal', 'Arial';
			font-weight: 800;font-size: 16px;color: #555555; width: 70%;height: 100%; margin: auto;">
			<table style="width: 100%;height: 100%; text-align: center; padding-top: 20px;">
			    <thead>
			        <td class="head" style="width: 25%;"><span style="color: rgb(75, 121, 2); font-size: 20px;">150</span>+</td>
			        <td class="head" style="width: 25%;"><span style="color: rgb(75, 121, 2); font-size: 20px;">50</span>+</td>
					<td class="head" style="width: 25%;"><span style="color: rgb(75, 121, 2); font-size: 20px;">269</span>MW/<span style="color: rgb(75, 121, 2); font-size: 20px;">537</span>MWH</td>
					<td class="head" style="width: 25%;"><span style="color: rgb(75, 121, 2); font-size: 20px;">10</span>年+</td>
			    </thead>
			    <tbody style="height: 60px;">
			        <tr>
			            <td>累计项目</td>
			            <td>分布国家与城市</td>
			            <td>累计出货量</td>
						<td>行业经验</td>
			        </tr>
					
			    </tbody>
			</table>
	  </div>
	</div>
    <!-- -------timeline------ -->
    <div ref="scrollOne"
         class="clearfix pab60 hezhuo animated slow hide"
         :class="{ 'show fadeIn': showOne }">
      <header class="head center">
      </header>
	  <div style="width: 70%;height: 100px; margin: auto;">
		  <div style="float: left; max-width:500px;max-height: 500px;padding-top: 100px;">
			  <img src="../assets/images/timeline.jpg" />
		  </div>
		  <div style="max-width:500px; float: right;">
		    <timeline :timeline-list="timelineArr"></timeline>
		  </div>
	  </div>
    </div>
    <!-- -------荣誉资质------ -->
    <div ref="scrollTwo"
         class="scene clearfix pab60 animated slow hide"
         :class="{ 'show fadeIn': showTwo }">
      <header class="mgt60">
        <p class="font_24 center font_b sce-title">荣誉资质</p>
		<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
		<br />
        <p class="center font_16 mgt20 pad_lr50 sce-desc">
          用科技创新为客户创造价值
        </p>
      </header>
      <div class="container mgt80 flex_box"
           id="jqviewer1">
        <div class="show_big1 fl">
      		  <img src="@/assets/images/zhengshu/gjgxjs.jpg"
               alt="高新认证企业证书" />
        </div>
		<div class="show_big fl">
			<img src="@/assets/images/index/zhuanli_1.png"
				 alt="zhuanli" />
		</div>
		<div class="show_big fl">
			<img src="@/assets/images/index/zhuanli_2.png"
				 alt="zhuanli" />
		</div>
        <div class="show_big fl">
			<img src="@/assets/images/index/EBO.png"
				 alt="EBO" />
        </div>
		<div class="show_big fl">
			<img src="@/assets/images/index/TestEBO.png"
				 alt="EBO" />
		</div>
		<div class="show_big fl">
		  <img src="@/assets/images/index/L3060chuneng.jpg"
		       alt="软著L3060" />
		</div>
      </div>
	  <div class="container mgt80 flex_box"
	       id="jqviewer1">
		<div class="show_big fl">
		  <img src="@/assets/images/index/mbmu.jpg"
		       alt="软著mbmu" />
		</div>
		<div class="show_big fl">
		  <img src="@/assets/images/index/scada.jpg"
		       alt="软著scada" />
		</div>
	    <div class="show_big fl">
	      <img src="@/assets/images/index/SiteController.jpg"
	           alt="软著sitecontroller" />
	    </div>
	    <div class="show_big fl">
	      <img src="@/assets/images/index/ynems.jpg"
	           alt="软著ynems" />
	    </div>
		<div class="show_big fl">
		  <img src="@/assets/images/index/jiayongchunengems.jpg"
		       alt="软著jiayong" />
		</div>
	  </div>
    </div>
    <!-- -------合作伙伴------ -->
    <div ref="scrollThree"
         class="partner clearfix pab60 animated slow hide"
         :class="{ 'show fadeIn': showThree }">
      <header class="mgt60">
        <p class="font_24 center font_b par-title">合作伙伴</p>
		<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
		<br />
      </header>
      <div class="container mgt40">
        <div class="clearfix par-content">
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/huoni.png"
                   alt="" />
            </div>
          </div>
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/energport.png"
                   alt="" />
            </div>
          </div>
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/shenghong.png"
                   alt="" />
            </div>
          </div>
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/epc.png"
                   alt="" />
            </div>
          </div>
          <div class="par-box">
            <div class="box-img">
              <img src="@/assets/images/sysindex/huoban/Invenergy.png"
                   alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------权威资质认证------ -->
    <!-- <div ref="scrollFour"
         class="b_999 clearfix pab60 animated slow hide"
         :class="{ 'show fadeIn': showFour }">
      <header class="mgt60">
        <p class="font_24 center font_b">荣誉资质</p>
		<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
		<br />
        <p class="center font_16 mgt20 pad_lr50">
          用科技创新为客户创造价值
        </p>
      </header>
      <div class="container mgt80 flex_box"
           id="jqviewer1">
        <div class="show_big1 fl">
		  <img src="@/assets/images/zhengshu/gjgxjs.jpg"
               alt="计算机软件证书" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/L3060chuneng.jpg"
               alt="计算机软件证书" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/mbmu.jpg"
               alt="增值电信业务经营许可证" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/scada.jpg"
               alt="高新技术企业证书" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/SiteController.jpg"
               alt="中国商业联合会知识产权分会会员" />
        </div>
        <div class="show_big fl">
          <img src="@/assets/images/index/ynems.jpg"
               alt="高新技术产品证书" />
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import commonTitle from '@/components/common/commonTitle.vue'
import Timeline from '@/components/common/timeline.vue'
import autoPlay from '@/components/common/AutoPlay.vue'
export default {
  name: 'Home',
  components: {
	commonTitle,
	Timeline,
	autoPlay,
  },
  data() {
    return {
	timelineArr:[{date:"2023",title:"再创新高",content:"公司累计储能项目突破450+MWH"},
		{date:"2022",title:"扬帆起航",content:"储能智能维护设备上线;累计储能项目突破200+MWH"},
		{date:"2021",title:"研发突破",content:"第二代EMS硬件上线，BMS软件实现大规模应用;累计储能项目突破150+MWH"},
		{date:"2019",title:"项目上线",content:"72MW/72MWh储能项目上线"},
		{date:"2018",title:"公司成立",content:"成都忆能科技有限公司创立"},
		],
	imgsrcList:
	  [
		// {id: 3, src: require('@/assets/images/banner/3.jpg')},
		{id: 2, src: require('@/assets/images/banner/3.jpg')},
	    {id: 1, src: require('@/assets/images/banner/2.jpg')},
	    {id: 0, src: require('@/assets/images/banner/1.jpg')}
	  ],
	  datas:["aboutus"],
      proList: [
        {
          name: '深耕储能',
          icon: require('@/assets/images/sysindex/icon_box.png'),
          desc1: '丰富的全球储能行业经验',
          desc2: '专注储能项目开发和运营',
        },
		{
		  name: '技术雄厚',
		  icon: require('@/assets/images/sysindex/icon_cross.png'),
		  desc1: '软、硬件自主开发',
		  desc2: '一流储能解决方案',
		},
        {
          name: '简单易用',
          icon: require('@/assets/images/sysindex/icon_easy.png'),
          desc1: '全流程监控管理',
          desc2: '轻松上手',
        },
        {
          name: '系统稳定',
          icon: require('@/assets/images/sysindex/icon_stable.png'),
          desc1: '400+MWH项目在线安全稳定运行',
          desc2: '',
        },
        // {
        //   name: '多端会务',
        //   icon: require(''),
        //   desc1: '支持电脑端、小程序、H5等',
        //   desc2: '随时随地即可参会',
        // },
        {
          name: '自定义方案',
          icon: require('@/assets/images/sysindex/icon_expand.png'),
          desc1: '多套方案供选择',
          desc2: '根据客户需求高度定制化',
        },
        {
          name: '覆盖全球',
          icon: require('@/assets/images/sysindex/icon_allword.png'),
          desc1: '产品遍布美国、加拿大、墨西哥、',
          desc2: '智利等国家',
        },
      ],
      hezuoList: [
		{
		  name: '解决方案',
		  icon: require('@/assets/images/index/fa.png'),
		  desc1:
		    '根据需求提供储能解决方案，包括系统架构方案设计及定制化功能开发、以及售后维护。',
		},
        {
          name: '系统集成',
          icon: require('@/assets/images/index/hezuo1.png'),
          desc1:
            '提供系统所需的软硬件产品及文档，提供系统设计、结构设计、电气设计整套集成方案，为项目最终上线运行验收负责。',
        },
        {
          name: '运营管理', 
          icon: require('@/assets/images/index/zy.png'),
          desc1:
            '提供储能集成的全方面运营管理和维护。',
        }
      ],
      linyuList: [
        // {
        //   name: '室内储能',
        //   icon: require('@/assets/images/sysindex/hangye/icon_first.png'),
        //   bg: require('@/assets/images/hezuo-1-1.png'),
        //   desc1: 'MEETING / PROMOTION',
        // },
        {
          name: '工商业储能',
          // icon: require('@/assets/images/sysindex/hangye/icon_second.png'),
          bg: require('@/assets/images/hezuo-1-2.jpg'),
          desc1: 'SUMMIT / FORUM',
        },
        {
          name: '电网侧储能',
          // icon: require('@/assets/images/sysindex/hangye/icon_third.png'),
          bg: require('@/assets/images/hezuo-3-1.png'),
          desc1: 'TRAINING / EDUCATION',
        },
        {
          name: '其它储能',
          // icon: require('@/assets/images/sysindex/hangye/icon_fourth.png'),
          bg: require('@/assets/images/hezuo-4-1.jpg'),
          desc1: 'MEETING / CELEBRATION',
        },
      ],
      showOne: false,
      showTwo: false,
      showThree: false,
      showFour: false,
    }
  },
  methods: {
	jump(indexNum) {
		switch(indexNum){
			case 0:
				localStorage.setItem("cec", "container-1");
				this.$router.push({ path: "/Product"});
				break;
			case 1:
				localStorage.setItem("cec", "container-2");
				this.$router.push({ path: "/Product"});
				break;
			case 2:
				localStorage.setItem("cec", "container-3");
				this.$router.push({ path: "/Product"});
				break;
			case 3:
				localStorage.setItem("cec", "container-4");
				this.$router.push({ path: "/Product"});
				break;
		}
	},
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let oneHeight = this.$refs.scrollOne.offsetHeight
      let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight
      let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight
      // let fourHeight = this.$refs.scrollFour.offsetHeight + threeHeight
      if (scrollTop > oneHeight) {
        this.showOne = true
      }
      if (scrollTop > twoHeight) {
        this.showTwo = true
      }
      if (scrollTop > threeHeight) {
        this.showThree = true
      }
      // if (scrollTop > fourHeight) {
      //   this.showFour = true
      // }
    },
  },
  mounted() {
	window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style scoped>
</style>
